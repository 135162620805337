/*
 * 1. Mixins
 * 2. General styles 
 * 3. Mobile 
 * 4. Desktop
*/


/* 1. Mixins
 * **********************************************************************
*/

@mixin menu_item_has_children_arrow() {
    padding-right: 1em;
   &:before {

   }  
}

@mixin menu_item_active_link () {

    background-color: #fff;
    background-color: transparentize(#fff, 1-0.8);
    border: 4px solid #fdf51f;
    color: #616161;
    color: transparentize(#2C6AD2, 1-0.8)
}


/* 2. General styles 
 * **********************************************************************
*/

.menu-top {
    list-style: none;

    // General styles
    li {
        position: relative;
        a {
            text-decoration: none;
            @extend %transition;
        }       
    }

}

.menu-top li.menu-item-has-children {
    @include menu_item_has_children_arrow();
}


/* 3. Mobile
 * **********************************************************************
*/

// MB
@media (max-width: bp('ds_')-1px ) {


    .menu-top {

        @include flex(flex-start, center);
        flex-direction: column;
        

        li {
            width: 100%;
            // max-width: 260px;
            max-width: $mobile_list_items_metric;

            a {
                display: block;
                font-size: 22px;
                line-height: 45px;
                height: 51px; //45px;
                // ? border
                border: 3px solid rgba(255, 255, 255, 0);  
                border-radius: 30px;
                background: rgba(255, 255, 255, 0);

                text-align: center;
                text-transform: uppercase;
                color: #fff;  


                &:hover{
                    background: #FFFFFF;
                    border: 3px solid #FDF51F;     
                    color: $text;               
                }
            }

        }

    }
    .menu-top{

         // display: none;
    } 
} /* media end */


/* 4. Desktop
 * **********************************************************************
*/

// TB || DS +
@media (min-width: bp('ds_')+0px ) {

    .menu-top {
        @include flex(flex-end, center);
        z-index: 200;
        position: relative;
        padding: 8px 0;

        li {

            a {
                font-family: $font_family_head;
                border-radius: 40px;
                border: 4px solid transparent;
                // padding: 8px 28px 7px 28px;
                padding: 8px 24px 7px 24px;
                letter-spacing: 0px;
                display: block;
                // font-size: 30px;
                font-size: 28px;
                line-height: 1;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
                font-weight: 300;

                &:hover,
                &.current-menu-item {
                    @include menu_item_active_link;
                }
            }
        }
        li.current-menu-item {
            a {
                @include menu_item_active_link;
            }
        }

    }


} /* media end */

