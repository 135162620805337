/*
DOCSS - Design-orientired CSS
 
Make your code like design with design terms like:
    Color scheme
    Typographic scheme
    Vertical rhytm
    Horizontal rhytm
    Height leveling
*/

@mixin height_leveling($type){
    $padding_leveling: ($l_h - $l_h_head) / 2;
    // line-height: $l_h_head; 

    @if $type == heading { // +
        line-height: $l_h_head;
        padding-top: $padding_leveling;
        padding-bottom: $padding_leveling;        
    } 
    @if $type == heading_double { // +
        line-height: $l_h_head;
        padding-top: $padding_leveling;
        // padding-bottom: $padding_leveling;
        margin-bottom: $padding_leveling;
        // height: 2 * $l_h_head + 2 * $padding_leveling;
        height: 2 * $l_h_head + 1 * $padding_leveling;
        overflow: hidden;
        text-overflow:  ellipsis;       
    } 

    @else if $type == text { // +
        line-height: $l_h;
    }
    @else if $type == text-compressed { //
        // line-height: $l_h_medium;
        line-height: normal;
    }    
    @else if $type == base { // +
        line-height: $l_h_base;
    }
    @else if $type == button { // ++
        line-height: $l_h;
        height: 1.5 * $l_h_base;
    }    
    
    @else if $type == fullheight {  // +
        @extend %fullheight;
    }
}


@mixin v_rhytm($type, $lh) {

    @if $type == heading {
        // line-height: $l_h_head;
        // padding-top: $padding_leveling;
        // padding-bottom: $padding_leveling;  

    } @else if $type == text {
        margin-top: $lh * 0.5;
        margin-bottom: $lh * 0.5;
    } 

      @else if $type == section {
        padding-bottom: $lh * 2;
    } @else if $type == section-title {
        margin-top: 2 * $l_h;
        margin-bottom: 2 * $l_h;
  
    } @else if $type == section-block {
        margin-top: 2 * $l_h;
        // margin-bottom: 2 * $l_h;  
    }
     @else if $type == block { // +
        margin-top: -0.5 * $l_h;
        margin-bottom: -0.5 * $l_h;         
    } @else if $type == item { // +
        margin-top: $lh * 0.5;
        margin-bottom: $lh * 0.5;
    }
     @else if $type == block-card {  // +
        margin-top: -$columns_gap+px;
        margin-bottom: -$columns_gap+px;
    } @else if $type == card { // +
        margin-top: $columns_gap+px;
        margin-bottom: $columns_gap+px;
    }
     @else {
        
    }    
}


@mixin h_rhytm($type, $fs) {

    @if $type == forms_element {
        padding-left: $columns_gap+px;
        padding-right: $columns_gap+px;
        padding-left: $fs * 1.25;
        padding-right: $fs * 1.25;      
    } 
    @else if $type == nav_element {

        padding-left: $fs * 2;
        padding-right: $fs * 2;         
    }

    @else if $type == column { // +
        padding-left: $columns_gap+px;
        padding-right: $columns_gap+px;      
    }

    @else if $type == card { // +
        padding-left: 4/3 * $columns_gap+px;
        padding-right: 4/3 * $columns_gap+px;       
    }
    @else if $type == grid { // 
        padding-left: 2 * $columns_gap + px;
        padding-right: 2 * $columns_gap + px;       
    } 
}


@mixin typo($type) {

    @if $type == wrerwewreq {

    } @else if $type == title { // +
        font-weight: 400;
        letter-spacing: $l_s_big;
        // text-transform: uppercase;
        font-variant: small-caps;
        text-transform: lowercase;

    } @else if $type == heading { // ++ // action__item-t:;itle
        font-weight: 500;
        letter-spacing: $l_s;

    } @else if $type == text { // +++...
        letter-spacing: $l_s;
    }   
     @else if $type == date { // +
        font-style: italic;
        font-weight: 300;
    }
    @else if $type == price { // ++
        font-weight: bold;
        letter-spacing: $l_s;
    }
    @else if $type == button { // +
        font-weight: 500;
        letter-spacing: $l_s;
        text-transform: uppercase;
    }

    // title-mini ? name
    // font-weight: 400;
    // letter-spacing: $l_s;
    // x, x 

    @else if $type == name { // +
        font-weight: 400;
        letter-spacing: $l_s;
    }    
}


@mixin color_scheme($type) {

    @if $type == default { // 
        background-color: $white;
        color: $text; 
    }
    @else if $type == 'default-reverse' { // 
        background-color: $text;
        color: $white; 
    }
    @else if $type == 'dark' { // +
        background-color: $grey_dark;
        // color: $white_dark; 
        color: $grey_super_light; 
    }
    @else if $type == 'dark-accent' { // +
        background-color: $grey_dark;
        color: $accent_alt; 
    }
    @else if $type == 'accent' { // +
        background-color: $accent;
        color: $white; 
    } 
}
