
// Input data:
$columns: 12;
$columns_gap: 15;
$container_width: 1180;
// $column_name: 'ds_';
$breakpoints_between: 'responsive'; // 'responsive' || 'fixed'. When responsive - 'container_max' don't use.


$modules_display: ( 
    block: 1,
    inline-block: 0,
    flex: 1
);


$devices: ( 
    mv_: (
        breakpoint: 360,
        container_max: 340,
        font_base: 15px,
        ms_coeff: 1.10,
    ),
    mh_: (
        breakpoint: 576,
        container_max: 540,
        font_base: 15px,
        ms_coeff: 1.10,
    ),
    tb_: (
        breakpoint: 768,
        container_max: 750,
        font_base: 15px,
        ms_coeff: 1.17,
    ), 
    ds_: (
        breakpoint: 1024,
        container_max: 960,
        font_base: 16px,
        ms_coeff: 1.17
    ),
    hd_: (
        breakpoint: 1200,
        container_max: 1180,
        font_base: 16px,
        ms_coeff: 1.17
    )      
);


// Import module 
@import 'node_modules/mw-grid/src/_mwgrid.scss';