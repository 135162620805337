@charset 'utf-8';

/*
 * Table of contents   Содержание
 *
 *  0. Imports
 *  1. General Vars, Mixins, Extends

 *  14.
 *
 */


@import 'services/services'; // services and helpers functions
// @import 'libs';         // external libs
// @import 'fonts';        // fonts add
@import 'mwgrid';       // layout framework

@import 'vars';
@import 'mixins';
// @import 'base';         // base, 

// @import 'blocks&components';   // moved to -> _base

$accent: #3c9;
$accent: #9c3;


/* 1. General, structure
 * **********************************************************************
 *  
*/

#page {
    overflow: hidden;
}


.name,
.name_s,
article h1,
.short .title a {

    font-size: 32px !important;

    @media (min-width: bp('tb_')+0px) {
        font-size: 48px !important;; 
    }
    @media (min-width: bp('ds_')+0px) {
        font-size: 54px !important;; 
    }     
}


/* 2. Header
 * **********************************************************************
 *  
*/


.header{
    width: 100%;
}
.header-top{

}
.logo{

    img{
        width: auto; max-width: 100%; height: auto;
    }
}

@media (max-width: 768px) {
   
    
} /* media end */


.header-new {
    height: auto;
    min-width: auto;
    padding: 1/2*$l_h  0;

    @media (min-width: bp('hd_')+0px)  {
        padding: 1*$l_h  0;
    }    
}

.header-left,
.header-right {
    @extend .mv_12;


    @media (max-width: bp('ds_')-1px)  {
        padding-left: 15px;
        padding-right: 15px;
    }    
}

   

.header-left {


    @media (max-width: bp('ds_')-1px)  {
        @include flex(space-between, center);
    }

    @media (min-width: bp('ds_')+0px)  {
        width: 40%;
    }
    @media (min-width: bp('hd_')+0px)  {
        width: 50%;
    }
}

.header-right {
    @media (min-width: bp('ds_')+0px)  {
        width: 60%;
        text-align: right;
    }    
    @media (min-width: bp('hd_')+0px)  {
        width: 50%;
    }  
}


.header-new {

    .logo {

    }

    @media  (max-width: bp('ds_')-1px)  {
        .logo {
            position: static;
            margin-top: 0;
            width: 100px;

            a {
                align-items: center;
                font-size: 32px;
            }
            span {
                padding-top: 0;
                margin-top: 0;
                margin-left: 0;
            }
        }

        &__contacts-col {
            display: flex;
            align-items: center;
            p,
            p:last-child {
                width: 50%;
                margin: 10px  0;
            }
            a {
                font-size: 17px;
            }
        }
        &__contacts-item-schedule {
            font-size: 15px;
        }


        // TEMP DEV
        .header-right {
            // display: none;
        }
    }


    @media (min-width: bp('ds_')+0px)  {
        .logo {
            margin-top: -1*$l_h;
        }

        &__contacts {
            p {
                justify-content: flex-end;
            }
        }

    }


    @media (min-width: bp('ds_')+0px) and (max-width: bp('hd_')-1px) {
        .logo {
            position: static;
            margin-top: 0;
            width: 50%;
            a {
                align-items: center;
                font-size: 48px;
            }
            span {
                padding-top: 0;
                margin-top: -20px;
            }
        }
    }    
}


.menu-trigger-outer  {
    width: 40px;

    @extend .ds_hidden;
    @media (min-width: bp('ds_')+0px)  {
        display: none;
    }
}
.menu-trigger {
// position: absolute;
    @include flex(center, center, column);
    width: 40px;
    cursor: pointer;

    span {        
        width: 100%;
        height: 6px;
        margin: 3px 0;

        background: #005FD2;
        border-radius: 10px;    
    }
}

/* . Services
 * **********************************************************************
 *  
*/

#service {

    .item-outer {
        // column width on HD
        @media (min-width: bp('hd_')+0px) {
            width: 20%;
        }
        .item {
           
           width: 100%;
           margin-bottom: 1*$l_h;
        }
    }
}


/* . About
 * **********************************************************************
 *  
*/

#about_us {

    .about_us {
        
        @media (min-width: bp('tb_')+0px) {
            flex-wrap: nowrap;
        }
    }

    .m_left {
        width: 183px;
    }

    .left-outer {
        // w = w 183 + m 40
        @include width(223px, 223px);
    }

    .right-outer {
        width: 100%;
    }
}


/* . Feed back
 * **********************************************************************
 *  
*/

#feed_back {

    .wpcf7-form {

        p {
            // max-width: 33%;
            width: 100%;
            margin-bottom: 1*$l_h;

            @media (min-width: bp('tb_')+0px) {
                max-width: 33%;
                max-width: calc(32% - 20px - 20px);
                // margin-bottom: 0;
            }

            input {
                width: 100%;
            }
        }
    }
}


/* . Reviews
 * **********************************************************************
 *  
*/

#reviews {
    .list.vid_2 {
        .comment  {
            width: 100%;
            margin-bottom: 1.5*$l_h;
            
            @media (min-width: bp('ds_')+0px) {
                max-width: 48%;
                // margin-bottom: 0;
            }
        }

    }
} 
// #reviews .list.vid_2 {
#reviews #comments-wrap .list.vid_2 {
    width: 100%; }

/* . Salle
 * **********************************************************************
 *  
*/

#salle {

    // min-width: 1200px;
    min-width: auto!important;

    // @media (min-width: bp('ds_')+0px) {
    //     padding: 60px 100px 60px 100px;
    // }    

    .container {
        .salle {
            
            padding: 30px 0 30px 0;

            @media (min-width: bp('ds_')+0px) {
                padding: 60px 100px 60px 100px;
            }  

            .txt {
                max-width: 100%;
                margin-bottom: 1*$l_h;
                @media (max-width: bp('tb_')-1px) {
                    font-size: 20px;
                }                  
            }

        }

            .wpcf7-form {

                p {
                    // max-width: 33%;
                    width: 100%;
                    margin-bottom: 1*$l_h;

                    @media (min-width: bp('tb_')+0px) {
                        max-width: 33%;
                        max-width: calc(32% - 20px - 20px);
                        margin-bottom: 0;
                    }

                    input {
                        width: 100%;
                    }
                }
            }
    }

}


/* . News
 * **********************************************************************
 *  
*/

#news {

    .list_3 {
        flex-wrap: wrap;

        .item__3 {
            width: 100%;
            margin-bottom: 1.5*$l_h;
          
            // @media (max-width: bp('mh_')-1px) {
            //     // display: block;
            // }
            @media (min-width: bp('ds_')+0px) {
                max-width: 48%;
                // margin-bottom: 0;
            }

            .txt {
                padding-right: 10px;
            }
            .thumb {
                @media (max-width: bp('mh_')-1px) {
                    max-width: 140px;
                }
            }
        }
    }

}


/* . Team
 * **********************************************************************
 *  
*/

@media (max-width: bp('ds_')-1px) {
    #team {
        .list{

            // li == .item
            li {
                display: flex;
                align-content: space-between;

                width: 100%;
                margin-bottom: 2.25em;  
                margin-left: 0; 
                margin-right: 0;

                .thumb {
                    max-width: 150px;
                    min-width: 150px;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                
                // block with content
                .hide {
                    position: relative;
                    left: 0;
                    transform: none;
                    opacity: 1;
                    visibility: visible;

                    margin-left: -99px;
                    height: 200px;
                    width: 100%;
                    max-width: 100%;


                    .t1 {
                        font-size: 16px; // 20 -> 18
                        @include text_overflow;
                    }
                    .t2 {
                        font-size: 20px; // 26 -> 20
                    }
                }            
            }
        } 

    }

}
@media (max-width: 479px) {
    #team {
        .list{

            // li == .item
            li {

                .thumb {
                    max-width: 120px;
                    min-width: 120px;
                }
            }
        }
    }
}


/* . Main slider
 * **********************************************************************
 *  
*/

#main_slider {
    // margin-bottom: 70px;
    // min-width: 1200px; 
    
    min-width: auto;

    @media (max-width: bp('tb_')-1px) {
        .wrap {
            height: 300px;
            min-width: auto;
        }
        .box {
            background-size: cover;
            min-width: auto;
        }
        .txt {
            display: none;
        }
    }

    @media (min-width: bp('tb_')+0px)  and  (max-width: bp('ds_')-1px) {
        .wrap {
            min-width: auto;
        }        
        .box {
            min-width: auto;
        }
        .txt {
            right: 0;
        }
    }

}

#main_slider .box 

/* . Comments
 * **********************************************************************
 *  
*/

.add_comment  {


    @media (max-width: bp('ds_')-1px) {

        .lop_left {
            width: 100%;
            input {
                text-align: left;
                padding-left: 20px; 
                padding-right: 20px;
            }
            
        }
        .lop_right {
            width: 100%;
        }
    }

}


/* . Contacts Bottom (Map)
 * **********************************************************************
 *  
*/

#contact_bottom {
    min-width: auto;
     
    @media (max-width: bp('ds_')-1px) {
        .info {
            display: none;
        } 
    }
}





/* . Content ( Text )
 * **********************************************************************
 *  
*/

.text  {

    @media (max-width: bp('ds_')-1px) {

        table {

        }

    }

}

// Page Contacts ( kontakt/ )
.page-id-35  {
    .text  {

        @media (max-width: bp('ds_')-1px) {

            table {
                float: none !important;
                margin: 0 auto;
                min-width: 90%;
            }

        }
        @media (min-width: bp('ds_')+0px) and (max-width: bp('hd_')-1px) {
            img {
                max-width: 30% !important;
            }            
            table {
                max-width: 65%;
            }

        }

    }
}


/* . Category.php
 * **********************************************************************
 *  
*/

.short {
    &-inner {
        @include flex(flex-start, flex-start);
        padding: 15px 15px 25px 15px;

        .thumb {
            width: 215px;
            padding-right: 15px;
        }
        .info {

        }

        @media (max-width: bp('ds_')-1px) {

            .thumb {
                width: 185px;
            }
            .info {

            }            
        }

        // @media (max-width: bp('tb_')-1px) {
        @media (max-width: bp('mh_')-1px) {
            

            @include flex(flex-start, flex-start, column);

            .thumb {
                width: 100%;
                max-width: 300px;
                margin-bottom: 20px;
            }
            .info {

            }            
        }
    }


}


/* . Single.php
 * **********************************************************************
 *  
*/

// .single - is wp template class
.single  {

    .title-outer {

        @media (max-width: bp('tb_')-1px) {
            display: flex;
            flex-direction: column-reverse;
            .title {

            }
            .thumb {

            }
        }


    }

}


/* . Nav
 * **********************************************************************
 *  
*/

.section-nav {

    @media (min-width: bp('ds_')+0px ) {
        background: linear-gradient(to right, #21bbff, #2c6ad2);    
    }
}

.nav-outer {
    padding-left: 20px;
    padding-right: 20px;
    // background: linear-gradient(166.23deg, #21BBFF 2.91%, #2C6AD2 98.31%);
    @extend %transition;

    @media (max-width: bp('ds_')-1px ) {
        z-index: 200;
        position: fixed;
        right: 0;
        top: 0;

        width: 100%;
        max-width: 400px;
        background: linear-gradient(166.23deg, #21BBFF 2.91%, #2C6AD2 98.31%);

        // States
        transform: translateX(100%);

        &.active {
            transform: translateX(0%);
        }        
    }

    @media (min-width: bp('ds_')+0px ) {
        // background: linear-gradient(to right, #21bbff, #2c6ad2);
        // // States
        // transform: translateX(100%);

        // &.active {
        //     transform: translateX(0%);
        // }
    }   



}

.nav {

    &-close-outer {
        // padding: 10px 1em 1em 1em;
        $button_close_top_offset : 25px + 12px; // ~
        padding: 10px 0  1em 0;
        padding: $button_close_top_offset 0  1em 0;
        @include flex(flex-end, center);

    }
    &-close {
        $nav_close_metric: 44px;
        width: $nav_close_metric;
        height: $nav_close_metric;
        @include flex(center, center);

        background-color: #fff;
        border-radius: 50%;

        font-family: Roboto;
        font-size: 18px;
        line-height: 21px;
        color: #005FD2;

        @extend %transition;

        &:hover {
            cursor: pointer;
            background-color: #005FD2;
            color: #fff;
        }
    }

    // Inheritence, mixes ?
    @media (min-width: bp('ds_')+0px ) {
        &__close {
            display: none;
        }
        &__search {
            display: none !important;
        }
        &__visually-impaired {
            display: none;
        }
    }     
}


.search-outer {
    @include flex(center, center);
    padding-top: 36px;
    padding-bottom: 30px;

    .search_site {
        margin-right: 0;
        width: 100%;
        max-width: $mobile_list_items_metric;
        input {
            font-size: 16px;
            color: $text;
            &::placeholder {
                color: inherit;
                opacity: 1;
            }
            
        }
    }

}

.visually-impaired-outer {

}
.visually-impaired {
    padding: 10px 0;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    a {
        color: #fff!important;;
    }
}


/* . Menu
 * **********************************************************************
 *  
*/

@import 'blocks/menu-top'; 


/* . Footer
 * **********************************************************************
 *  
*/


@mixin footer_contacts_item_reset () {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        margin-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
            
    }
}


.footer {
    min-width: auto;
    position: relative;

    &:before {
        @include pseudoelement_left_top(0, 0, );
        width: 100%;
        height: 100%;
        background-color: #2C6AD2;
        background-color: transparentize(#000, 1-0.15);
    }

    // @media (min-width: bp('tb_')+0px )  and (max-width: bp('ds_')-1px ) {
    @media  (max-width: bp('ds_')-1px ) {

        height: auto;
        background-size: cover;

        .flx {
            flex-direction: column;
            padding-left: 20px;
            padding-right: 20px;

            // col left
            .fot_left {
                padding-top: 30px;
            }

            // col right
            .col_right {
                padding-bottom: 30px;
                justify-content: center;

                .copy {
                    margin-bottom: 20px;
                    width: auto;
                }
                .phone {
                    width: 100%;

                    .footer_row {
                        @include footer_contacts_item_reset;
                        .search_site {
                            margin-bottom: 40px;
                            width: 80%;
                            max-width: 400px;
                        }
                        
                    }
                    .fase {

                        @include footer_contacts_item_reset;
                    }
                }

            }
        }

        .logo_f {
            display: none;
        }
    }

}





