/*
 * 1. Colors
 * 2. Fonts
 * 3. Borders
 * 4. Z-index
 * 5. Animations
 * 
 * 
 * 
*/


// 1. Colors

$accent: #2491dd;
$accent_light: lighten($accent, 13%);  // #5daee6;   // #5fafe6 
$accent_dark: darken($accent, 11%); // #1b72ae;  // #1b6aa1

$grey : #9b999e;
$grey_light: lighten($grey, 23%); // $grey_super_light: lighten($grey_light, 12%);  // f5f4f5
$grey_lighter: lighten($grey, 30%);  //  #e8e7e9
$grey_super_light: lighten($grey, 35%);  //  #f5f4f5
$grey_dark: darken($grey, 14%); // #77757b;
$grey_super_dark: darken($grey, 40%); // #353437

// Semantic colors
$text: $grey_super_dark;
$white: #ffffff;
$black: #000000;

$success_color: #35dd24;
// $error_color  : #dd2435;
$error_color  : #ae1b28;
// $warning_color: #ddcd24; // #fbaf1c
$warning_color: #aea11b; //
$warning_color: #fbaf1c; //
$info_color   : #2491dd;


// 2. Fonts
$fb : 1rem;

$font_coeff : 1.19;
$font_h4    : $fb * $font_coeff;
$font_h3    : $font_h4 * $font_coeff;
$font_h2    : $font_h3 * $font_coeff;
$font_h1    : $font_h2 * $font_coeff;

$font_small   : $fb * 1/$font_coeff;
$font_smaller : $font_small * 1/$font_coeff;

// Semantical fonts
$font_forms_elements: $fb;


$l_s:  0.025em;
$l_s_big:  0.05em;

$l_h : 1.5em;
$l_h_head: 1.2em;

$font_family : "Roboto",  Verdana,  Arial, sans-serif;
$font_family_head : "Myriad Pro",  Tahoma,  Arial, sans-serif; // +


// 3. Borders
$bd_rad: 8px;


// 4. Z-index
$zindex_icon: 100;
$zindex_nav: 200;
$zindex_sticky: 300;
$zindex_modal: 400;
$zindex_tooltip: 500;


$sidebar_width: 260;
// $sidebar_offset: (40 - $columns_gap)+px;
$sidebar_offset: 40px;


// 
$mobile_list_items_metric: 260px; // +


// 5. Animations
$trns_dur: 0.4s;  //+ 
$transition_duration: 0.25s; // +



// . ?

$font_units_negative: 2; // font units less then 'base'
$font_size_names: (  
    'smaller',
    'small',
    'base',
    h6,
    h5,
    h4,
    h3,
    h2,
    'h1',
    'large'
);

$fonts: ();


/*
 
fonts
DS      MB
34      22
 */