/*
 * Extends
*/

%fullscreen{
    // position: relative; height: 100%;
    width: 100%; height: 100vh;    
}
%fullheight{
    position: relative; height: 100%;
    // width: 100%; height: 100vh;    
}
%clearfix{
    float: left;
    width: 100%;
    &:after{
        // content: "";
        // display: table;
        // float: left;
        // width: 100%;
    }
}
%shadow{
    // box-shadow: 0 0 8px 0px opacify($grey_super_dark, 0.1); 
    box-shadow: 0 0 8px 0px transparentize($grey_super_dark, 0.75); 
}
%top_list_item_link{
    display: block;
    color: $text;
    font-weight: 300;
    font-size: $font_small;
    // line-height: $l_h;
    line-height: inherit;
    color: $grey_dark;
}
%link_decor{
    color: $text;
    .themify{

    }
    &:hover{
        color: $accent_dark;
    }

}
%link_with_icon{
    position: relative;

    i.themify{               
        @include pseudoelement_right_top(0, 50%);
        transform: translateY(-50%);
        font-size: 1 * $fb; font-size: 85%;
    }    
}

%nav_primary_item {
    display: block;
    padding: 0.75em 2em;
    font-size: $fb;
    line-height: $l_h;
    letter-spacing: $l_s;
    transition: all 0.3s;
}

%border_fullwidth {

        position: relative;

        &:after {
            @include pseudoelement_left_top(-100%, 100%);
            width: 300%;
            height: 1px;
            background-color: $grey_super_light;
        }
}


%transition { // +
    transition: all $transition_duration;
}
%transition_fast {
    transition: all ($transition_duration / 2);
}

%border_circle { // +
    border-radius: 50%;
    // border-radius: 5%;
}

%image_link_hover {
    opacity: 0.9;
}